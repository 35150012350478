import { Link } from "gatsby"
import React from "react"
import tw, { styled } from "twin.macro"
import { brandRules } from "utils/brand"

const ProductLink = styled.div`
  ${tw`inline-block font-secondary p-3 px-4 m-2 border bg-grey-20 border-grey-20`}
  border-radius: 999px;
  font-size: 25px;
  line-height: 27px;
  padding-bottom: 7px;
  text-align: center;
  transition: all 0.15s;

  &:hover {
    ${tw`border-springgreen-light`}
    background-color: rgba(0, 171, 92, 0.5);
    box-shadow: 5px 5px 7px 0 rgba(0, 0, 0, 0.1);
  }
`

const BusinessQuestionStyles = styled.div`
  ${tw`text-white text-left flex flex-col l:flex-row-reverse`}

  .info {
    ${tw`w-full l:w-1/2 mt-6 l:pr-0 l:pl-10 l:mt-0`}

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;

      @media (min-width: 640px) {
        font-size: 36px;
        line-height: 45px;
      }
    }

    .body {
      ${tw`mt-5`}
      font-weight: 400;
      font-size: 14px;
      line-height: 24.5px;

      @media (min-width: 640px) {
        font-size: 18px;
        line-height: 27px;
      }
    }
    .footer {
      ${tw`flex flex-row items-center mt-12 flex-wrap`}
      text-wrap: nowrap;
    }
  }
`

const IndustryBusinessQuestion = ({ questions }) => {
  return questions.map((q, ind) => (
    <BusinessQuestionStyles key={ind}>
      <div className="info">
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: `<div>${q.question}</div>`,
          }}
        />
        <div
          className="body"
          dangerouslySetInnerHTML={{ __html: `<div>${q.answer.html}</div>` }}
        />
        {!!q.products && q.products.length > 0 && (
          <div className="footer">
            <div className="font-body text-base m:text-xl pr-3 font-bold text-nowrap">
              Relevant products:
            </div>
            <div className="-mx-2 flex flex-row flex-wrap">
              {q.products.map((product) => (
                <Link to={`/products/${product.key}`} key={product.key}>
                  <ProductLink>{product.title}</ProductLink>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* <div className="hidden l:block l:w-1/12" /> */}
      <div className="w-full l:w-1/2 flex flex-col rounded">
        <img src={q.headerImage.url} className="rounded object-cover" />
      </div>
    </BusinessQuestionStyles>
  ))
}

export default IndustryBusinessQuestion
