import React, { useRef, useState, useEffect } from "react"
import tw, { styled } from "twin.macro"
import { motion, AnimatePresence } from "framer-motion"

import StyledBlock from "components/newsroom/styled-block"
import Button from "components/button"
import ContactUsBg from "images/bg/industry-contact-us-bg.jpeg"
import ReactDOM from "react-dom"
import IconClose from "images/icons/close.inline.svg"
import { useMounted } from "utils/use-mounted"
import { navigate } from "gatsby"

const Styles = styled.div`
  ${tw`text-white py-20 m:py-44`}

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.49) 100%
    ),
    linear-gradient(
      108.42deg,
      #000000 -0.14%,
      rgba(70, 0, 175, 0.69) 48.48%,
      rgba(40, 169, 255, 0.7) 100.46%
    ),
    url(${({ background }) => background});
  background-size: cover;

  .content-wrapper {
    ${tw`mx-auto l:max-w-l`}

    max-width: 90%;

    ${StyledBlock}.secondary-section-header {
      ${tw`mx-auto`}

      @media (min-width: 1200px) {
        max-width: 900px;
      }
    }
  }
`

const BannerStyles = styled(motion.div)`
  ${tw`fixed bottom-0 left-0 w-screen bg-black bg-opacity-90 z-10`}

  height: 72px;

  .banner-wrapper {
    ${tw`l:max-w-l h-full flex flex-row justify-center items-center mx-auto pl-2 l:pl-0 pr-10 l:pr-0`}
    .text {
      ${tw`text-white text-sm m:text-base font-body`}
    }

    .action {
      ${tw`ml-6 m:ml-8 l:ml-16 flex-shrink-0`}
    }
  }
`
const Banner = ({ onClick, onDismiss, visible }) => {
  if (typeof document === `undefined`) {
    return null
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {visible && (
        <BannerStyles
          key="newsroom-subscribe-banner"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="banner-wrapper">
            <div className="text">
              Sign up for our press distribution list and get access to the
              latest press updates
            </div>
            <div className="action">
              <Button className="small" color="green" onClick={onClick}>
                Sign Up
              </Button>
            </div>
          </div>
          <button
            onClick={onDismiss}
            className="absolute top-6 right-1 m:right-3 l:right-6"
          >
            <IconClose />
          </button>
        </BannerStyles>
      )}
    </AnimatePresence>,
    document.getElementById("modal-container")
  )
}

const IndustryContactUs = ({ contentBlock }) => {
  const [showReminder, setShowReminder] = useState(false)
  const [dismissed, setDismissed] = useState(false)
  const sectionRef = useRef()
  const isMounted = useMounted()
  const showBanner = showReminder && !dismissed

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler)
    return () => {
      document.removeEventListener("scroll", scrollHandler)
    }
  }, [])
  function navigateToContactUs() {
    navigate("/contact")
  }

  function scrollHandler() {
    const rect = sectionRef.current?.getBoundingClientRect()

    setShowReminder(rect?.bottom < -920)
  }

  return (
    <>
      <Styles background={ContactUsBg} ref={sectionRef}>
        <div className="content-wrapper">
          <StyledBlock
            className="secondary-section-header"
            dangerouslySetInnerHTML={{
              __html: contentBlock.headerContent.html,
            }}
          />

          <div className="text-center mt-10 m:mt-16">
            <Button color="green" onClick={() => navigateToContactUs()}>
              Contact Us
            </Button>
          </div>
        </div>
      </Styles>
      {isMounted && (
        <Banner
          onClick={(e) => navigateToContactUs()}
          onDismiss={() => setDismissed(true)}
          visible={showBanner}
        />
      )}
    </>
  )
}

export default IndustryContactUs
