import React, { useState } from "react"
import tw, { styled } from "twin.macro"
import { motion, AnimatePresence, useAnimation } from "framer-motion"

import Lightbox from "components/lightbox"
import IconClose from "images/icons/close.inline.svg"
import ButtonLE from "components/button-le"

const IndustryProfile = styled.div`
  ${tw`py-20 m:py-32 px-2 flex flex-wrap-reverse l:flex-wrap justify-start items-start mx-auto max-w-s m:max-w-m l:max-w-l`}
  font-size: 15px;
  line-height: 27px;
  p,
  li {
    margin-top: 1.5rem;
  }
`

const IndustryTitle = styled.h2`
  ${tw`py-2 w-full font-display font-bold text-white text-center m:text-left`}
  font-size: 25px;
  line-height: 25px;
  @media (min-width: 640px) {
    font-size: 35px;
    line-height: 35px;
  }
  @media (min-width: 1024px) {
    font-size: 50px;
    line-height: 48px;
  }
`

const IndustryBody = styled.div`
  h2 {
    ${tw`font-display text-yellow`}
    font-size: 18px;
    line-height: 20px;
  }

  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding-left: 1em;
    text-indent: -0.7em;
  }

  li > div {
    display: inline;
  }

  li::before {
    content: "• ";
    color: #f7bc2d;
  }
`

export default ({ application, onClose }) => {
  const fadeIn = useAnimation()
  const [closing, setClosing] = useState(false)

  function scrollToTop() {
    if (!closing) {
      window.scrollTo({ top: 0 })
      fadeIn.start({ opacity: 1 })
    }
  }
  async function close() {
    setClosing(true)
    await fadeIn.start({ opacity: 0 })
    onClose()
    setClosing(false)
  }

  return (
    <>
      <AnimatePresence>
        {!!application && (
          <motion.div
            key="application-motion"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="absolute top-0 left-0 min-h-screen w-full z-50"
            onAnimationComplete={scrollToTop}
          >
            <Lightbox key="application-lightbox" onClick={close} opacity={90} />
            <motion.div initial={{ opacity: 0 }} animate={fadeIn}>
              <button
                onClick={close}
                className="absolute top-0 right-0 m-6 m:m-8"
              >
                <IconClose />
              </button>
              <IndustryProfile
                className="text-white font-body relative"
                key={application.slug}
                id={application.slug}
              >
                <div className="w-full l:w-5/12 l:mt-20 pt-6">
                  {application.coverImage && application.coverImage.url && (
                    <img
                      src={application.coverImage.url}
                      alt={application.title}
                      className="hidden l:block"
                    />
                  )}
                </div>
                <div className="hidden l:flex w-1/12"></div>
                <div className="w-full l:w-1/2">
                  {application.icon && (
                    <div className="flex flex-col items-center m:items-start pb-3">
                      <img
                        src={application.icon.url}
                        alt={application.title}
                        width={83}
                        height={83}
                      />
                    </div>
                  )}
                  <IndustryTitle>{application.title}</IndustryTitle>
                  <IndustryBody
                    dangerouslySetInnerHTML={{
                      __html: `<div>${application.body.html}</div>`,
                    }}
                  />
                  <ButtonLE
                    className="my-6 justify-center m:justify-start"
                    color="yellow"
                    to="/contact"
                  >
                    Contact Us
                  </ButtonLE>
                </div>
              </IndustryProfile>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
