import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const Container = styled.div`
  ${tw`bg-grey-10 font-body text-white text-center fixed flex flex-row items-center justify-center`}
  box-shadow: inset -2px -2px 3px 0 rgba(110,110,110,0.05), inset 2px 2px 3px 0 rgba(110,110,110,0.05);
  font-size: 15px;
  height: ${(props) => (props.sticky ? "60px" : 0)};
  line-height: 17px;
  letter-spacing: 0;
  transition: all 0.2s;
  top: 80px;
  width: 100%;
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    z-index: ${(props) => (props.sticky ? 49 : -1)};
    opacity: ${(props) => (props.sticky ? 1 : 0)};
  }
`

const CategoryFrame = styled.div`
  ${tw`p-4 px-5 flex flex-col items-center justify-center`}
  background: ${(props) =>
    props.selected ? "linear-gradient(180deg, #e3284c 0%, #aa0024 100%)" : ""};
  ${(props) =>
    props.selected ? "box-shadow: 0 0 5px 0 rgba(170,0,36,0.66);" : ""};
  ${(props) => (props.selected ? "color: white;" : "")};
  clip-path: polygon(16px 0%, 100% 0%, calc(100% - 16px) 100%, 0% 100%);
  cursor: pointer;
  height: 60px;
  margin-left: -8px;
  margin-right: -8px;
  max-width: 120px;
  position: relative;
  transition: all 0.2s;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) =>
      props.selected
        ? ""
        : "linear-gradient(180deg, #262626 0%, #262626 100%)"};
    z-index: -1;
    transition: opacity 0.1s linear;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
`

const Category = ({ selected, slug, children }) => {
  const link = slug ? `/industry/${slug}` : "/insights/"
  return (
    <Link to={link}>
      <CategoryFrame selected={selected}>
        <span>{children}</span>
      </CategoryFrame>
    </Link>
  )
}

const IndustriesHeader = ({ current }) => {
  const [isStuck, setSticky] = useState(false)

  const data = useStaticQuery(graphql`
    query INDUSTRY_HEADER {
      gcms {
        industriesConnection(orderBy: order_ASC) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
      }
    }
  `)
  const industries = data.gcms.industriesConnection.edges

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const sticky = currPos.y > 350
      if (sticky !== isStuck) {
        setSticky(sticky)
      }
    },
    [isStuck],
    false,
    true,
    300
  )

  return (
    <Container sticky={isStuck}>
      {industries.map(({ node }) => {
        return (
          <Category
            slug={node.slug}
            key={node.id}
            selected={node.slug === current}
          >
            {node.title}
          </Category>
        )
      })}
    </Container>
  )
}

export default IndustriesHeader
