import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import { motion, AnimatePresence } from "framer-motion"

import Lightbox from "components/lightbox"
import Check from "images/icons/check-bold.inline.svg"

const Current = styled.h1`
  ${tw`
    py-2 mr-3 font-display font-bold text-white text-center
    flex flex-row items-center justify-center
  `}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }
`

const IndustryLink = styled.div`
  ${tw`
    flex flex-row justify-between items-baseline
    text-white uppercase px-2 m:py-2 border-neonred mx-auto
  `}
  border-bottom-width: ${(props) => (props.selected ? "2px" : "0")};
  font-size: ${(props) => (props.selected ? "18px" : "15px")};
  line-height: 18px;
  @media (min-width: 640px) {
    font-size: ${(props) => (props.selected ? "18px" : "17px")};
    line-height: 20px;
  }
  width: 260px;
`

const Industry = ({ children, selected, slug }) => (
  <div className="flex w-full m:w-1/2 l:w-1/3 my-3">
    {!selected && (
      <IndustryLink className="font-body">
        <Link to={`/industry/${slug}`}>{children}</Link>
      </IndustryLink>
    )}
    {selected && (
      <IndustryLink selected className="font-display">
        {children}
        <Check className="ml-2" />
      </IndustryLink>
    )}
  </div>
)

const Container = styled.div`
  ${tw`absolute pt-0 z-20 w-full`}
  left: auto;
  right: auto;
  top: 60px;
  @media (min-width: 640px) {
    top: 100px;
  }
`

const Frame = styled.div`
  ${tw`relative pt-20 m:pt-32 mx-auto l:w-10/12 flex flex-col items-center`}
  background: #000000;
  border: 1px solid #ffffff;
  box-shadow: 4px 4px 18px 0 rgba(69, 69, 69, 0.5);
  border-radius: 5px;
`

const Button = styled.button`
  ${tw`relative mx-auto z-20 top-0 mt-4 m:mt-6 left-auto right-auto pl-6`}
  &:focus {
    outline: none;
  }
`

const Arrow = styled.span`
  height: 1rem;
  width: 1rem;
  display: block;
  margin: 0;
  margin-left: 0.5rem;
  top: 0.1rem;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  display: inline;
  transform: scale(0.5);
  @media (min-width: 640px) {
    transform: scale(1);
    margin-left: 1rem;
    top: 0.3rem;
  }

  .left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 20px;
    height: 5px;
    display: block;
    transform: rotate(35deg);

    border-radius: 2px;
  }
  .left-bar:after {
    content: "";
    background-color: white;
    width: 20px;
    height: 5px;
    display: block;

    border-radius: 6px 10px 10px 6px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
  }

  .right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 13px;
    width: 20px;
    height: 5px;
    display: block;
    transform: rotate(-35deg);

    border-radius: 2px;
  }
  .right-bar:after {
    content: "";
    background-color: white;
    width: 20px;
    height: 5px;
    display: block;

    border-radius: 10px 6px 6px 10px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
  }

  &.open .left-bar:after {
    ${tw`bg-yellow`}
    transform-origin: center center;
    transform: rotate(-70deg);
  }
  &.open .right-bar:after {
    ${tw`bg-yellow`}
    transform-origin: center center;
    transform: rotate(70deg);
  }
`

const IndustrySelector = ({ current }) => {
  const data = useStaticQuery(graphql`
    query INDUSTRY_SELECTOR {
      gcms {
        industriesConnection(orderBy: order_ASC) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
      }
    }
  `)
  const industries = data.gcms.industriesConnection.edges
  const currentIndustry = industries.find(({ node }) => node.slug === current)
  const title = currentIndustry ? currentIndustry.node.title : ""
  const [active, activate] = useState(false)
  function toggle() {
    activate(!active)
  }

  return (
    <Container>
      <Button onClick={toggle} className="flex flex-row items-center mx-auto">
        <Current>
          {title}
          <Arrow className={active ? "open" : ""}>
            <span className="left-bar" />
            <span className="right-bar" />
          </Arrow>
        </Current>
      </Button>
      <AnimatePresence>
        {active && (
          <motion.div
            key="industry-selector-motion"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Lightbox key="industry-selector-lightbox" onClick={toggle} />
            <div
              className="absolute top-0 w-full px-4"
              key="industry-selector-frame"
            >
              <Frame>
                <div className="flex flex-wrap w-full mx-auto pb-10 content-center">
                  {industries.map(({ node }) => (
                    <Industry
                      key={node.slug}
                      slug={node.slug}
                      selected={current === node.slug}
                    >
                      {node.title}
                    </Industry>
                  ))}
                </div>
              </Frame>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default IndustrySelector
