import React, { useState } from "react"
import { graphql, Link } from "gatsby"
// eslint-disable-next-line no-unused-vars
import tw, { styled } from "twin.macro"

import Layout from "components/layout"
import SEO from "components/seo"

import IndustrySelector from "components/industry-selector"
import Divider from "components/divider"
import AppLightbox from "components/application-lightbox"
import PartnerLogos from "components/partner-logos"
import FeaturedTestimonial from "components/sections/testimonial"
import FeaturedVideo from "components/sections/featured-video"
import PageHeader from "components/page-header"
import IndustriesHeader from "components/industries-header"
import LightSlantedSection from "components/sections/light-slant"
import FeaturedPressCarousel from "components/featured-press-carousel"
import SlantedSection from "components/sections/slant"
import StyledBlock from "components/newsroom/styled-block"
import { getSharePreviewUrls } from "utils/share-preview"
import IndustryContactUs from "components/industry-contact-us"
import IndustryBusinessQuestion from "components/sections/industry-business-question"

const BigButton = styled.button`
  ${tw`px-6 py-5 font-display uppercase text-white`}
  background: linear-gradient(180deg, #E3284C 0%, #AA0024 100%);
  border-radius: 5px;
  box-shadow: 4px 4px 15px 0 rgba(227, 40, 76, 0.5);
  font-size: 18px;
  line-height: 14px;
  text-align: center;
`

const Main = styled.div`
  background-image: linear-gradient(270deg, #180d28 0%, #000000 100%);

  .industry-business-questions-wrapper {
    ${tw`pt-16 m:pt-28 mx-auto max-w-s m:max-w-m l:max-w-l space-y-20 l:space-y-40 pb-24 l:pb-28 relative z-10`}

    max-width: 90%;
  }
`

const Container = styled.div`
  ${tw`pt-16 m:pt-32 px-2 flex flex-col justify-center items-center
    content-center mx-auto max-w-s m:max-w-m l:max-w-l`}
`

const SecondarySectionHeader = styled.div`
  ${StyledBlock} {
    ${tw`mx-auto mb-0 text-white`}

    max-width: 90%;

    @media (min-width: 1200px) {
      max-width: 900px;
    }
  }
`

const Industry = ({ data }) => {
  const { page, industry, contactUs } = data.gcms
  const { businessQuestions } = industry

  const [openApplication, setApplication] = useState(null)
  const [scrollY, setScroll] = useState(0)
  const contactUsCb = contactUs.find(
    ({ identifier }) => identifier === "industry-contact-us-section-header"
  )
  function showApp(application) {
    if (application === null) {
      setApplication(null)
      window.scrollTo({ top: scrollY })
    } else {
      setScroll(window.scrollY)
      setApplication(application)
    }
  }
  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <IndustriesHeader current={industry.slug} />
      <PageHeader
        bg={page && page.headerImage ? page.headerImage.url : false}
        contentClass="mt-8"
        content={industry.headerCopy.html}
        gradient={true}
      />
      <IndustrySelector current={industry.slug} />
      <Main className="text-white">
        <div className="text-center overflow-visible" style={{ height: "2px" }}>
          <Divider />
          <div style={{ position: "relative", top: "-30px" }}>
            <Link to="/contact">
              <BigButton>Contact us</BigButton>
            </Link>
          </div>
        </div>
        <Container>
          <SecondarySectionHeader>
            <StyledBlock
              className="secondary-section-header"
              dangerouslySetInnerHTML={{
                __html: `<h2>${industry.benefitsTitle}</h2>`,
              }}
            />
          </SecondarySectionHeader>
        </Container>
        <div className="industry-business-questions-wrapper">
          {businessQuestions && (
            <IndustryBusinessQuestion questions={businessQuestions} />
          )}
        </div>
        {contactUsCb && (
          <SlantedSection>
            <IndustryContactUs
              contentBlock={contactUsCb}
              modalContentBlock={{}}
              highlights={[]}
            />
          </SlantedSection>
        )}
        {page?.featuredPressArticles?.length > 0 && (
          <LightSlantedSection height="600px" className="pt-8 mb-24">
            <FeaturedPressCarousel articles={page.featuredPressArticles} />
          </LightSlantedSection>
        )}

        {!!page && !!page.featuredTestimonial && (
          <FeaturedTestimonial testimonial={page.featuredTestimonial} />
        )}
        {!!page && !!page.featuredVideo && (
          <FeaturedVideo video={page.featuredVideo} />
        )}
        {!!page && !!page.featuredLogosClients && (
          <div className="py-16 mx-auto max-w-s m:max-w-m l:max-w-l">
            <PartnerLogos data={page.featuredLogosClients} />
          </div>
        )}
      </Main>
      <AppLightbox
        application={openApplication}
        onClose={() => showApp(null)}
      />
    </Layout>
  )
}

export default Industry

export const query = graphql`
  query IndustryPage($slug: String, $path: String) {
    gcms {
      page(where: { path: $path }) {
        ...PageData
      }
      contactUs: contentBlocks(
        where: { identifier: "industry-contact-us-section-header" }
      ) {
        id
        identifier
        headerContent {
          html
        }
      }
      industry(where: { slug: $slug }) {
        id
        title
        slug
        headerCopy {
          html
        }
        featuredImage {
          url
        }
        benefitsTitle
        benefits
        benefitIcons {
          url
        }
        mainApplications {
          ...ApplicationData
        }
        mediumApplications {
          ...ApplicationData
        }
        applications {
          ...ApplicationData
        }
        businessQuestions {
          question
          answer {
            html
          }
          headerImage {
            id
            url
            fileName
            height
            width
            size
          }
          products {
            ... on GCMS_SolutionsCategory {
              id
              title
              key
            }
            ... on GCMS_SolutionsProduct {
              id
              title
              key
            }
          }
        }
        body {
          html
        }
      }
    }
  }
`
